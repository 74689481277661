/* eslint-disable react/prop-types */
/**
 *
 */

import React from 'react'
import callToActionImage from '../../images/logo-webcom.webp'
import '../header/style/desktop.scss'

const PopPup = ({ 
  callToAction,
  setCallToAction,
  setCallToActionB,
  absoluteLeftPosition,
  absoluteTopPosition, 
}) => {
  return (
    <div className={`containerCallToAction ${ callToAction ? 'visible' : 'novisible' }`} style={{ top: absoluteTopPosition, left: absoluteLeftPosition }}>
      <div className='callToActionBar' onClick={() => setCallToAction ? setCallToAction.setState({ callToAction: false }) : setCallToActionB(false)}>
        <span>X</span>
      </div>
      <div className='callToActionContent'>
        <img className='callToActionImage' src={callToActionImage} alt='call to action' />
        <span className='callToActionDescription'>Our partner brand Web.com delivers everything you need to grow your business. Web.com One Stop. All Done.</span>
        <span className='callToActionRedirectText'>You will now be redirected to Web.com</span>
      </div>
    </div>
  )
}

export default PopPup
