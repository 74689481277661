import React from 'react'

import BrandLogo from './../../images/WSB-Logo-Stacked-White.svg'

import './style/footer.scss'

const Footer = () => (<footer className='bg-darkgray'>
  <div className='container section-padding-small'>
    <div className='row'>
      <div className='col-lg-2 col-sm-3 col-6'>
        <h4>Company</h4>
        <ul>
          <li><a href='/terms'>Terms of Service</a></li>
          <li><a href='/abuse'>Abuse</a></li>
          <li><a href='https://newfold.com/privacy-center' rel='noopener' target='_blank'>Privacy</a></li>
          <li className='do-not-sell-my-info'><a href='https://www.newfold.com/privacy-center/addendum-for-california-users' rel='noopener' target='_blank'><h4>Do Not Sell My Personal Information</h4></a></li>
          <li className='ot-sdk-show-settings'><a href='javascript:void(0)' ><h4>Cookie Settings</h4></a></li>
        </ul>
      </div>
      <div className='col-lg-4 medium-hidden' style={{ marginLeft: 'auto', textAlign: 'right' }}>
        <img
          src={BrandLogo}
          style={{ opacity: 0.25, pointerEvents: 'none', userSelect: 'none', maxWidth: 200 }}
          alt='brand logo' />
      </div>
    </div>
  </div>
</footer>
)
export default Footer
