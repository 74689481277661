import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Link } from 'gatsby'

import Button from '@eig-builder/core-ui/Button'
import callToActionImage from '../../images/logo-webcom.webp'
import {
  DataElementLocations,
  DataElementTypes,
  getDataProperty
} from '@eig-builder/core-utils/helpers/tagging-helper'

import { goToHref } from './../../helpers/go-to-helper'

import ControlLink from './../control-link'

import './style/desktop.scss'
import './style/mobile.scss'

import PopUpCallToAction from '../pop-up'

class Header extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      callToAction: false
    }
  }

  onOpenMenu = () => {
    this.setState({
      open: true
    })
  }

  onCloseMenu = () => {
    this.setState({
      open: false
    })
  }

  redirectWebdotcom = websiteUrl => {
    setTimeout(() => {
      document.location.href = websiteUrl
      this.setState({
        callToAction: false
      })
    }, 5000)
  }

  getHeaderStyle(zindex){
    return {
      zIndex:zindex
    }
  }

  getHeaderClass(isWhite, isOpen){
    let wrapperClassName = ''
    if (isWhite) {
      wrapperClassName += ' white'
    }
    if (isOpen) {
      wrapperClassName += ' menu-open'
    }
    return wrapperClassName;
  }

  render () {
    const { isWhite, zindex } = this.props
    const { open } = this.state

    return (
      <header style={this.getHeaderStyle(zindex)} className={this.getHeaderClass(isWhite, open)}>
        <div className='container-fluid'>
          <Link to='/' className='logo' />
          <span className='screen-reader-text'>
            If you are using assistive technology and are unable to read any
            part of the WebsiteBuilder.com website, or otherwise have
            difficulties using the WebsiteBuilder.com website, please call
            844-211-7882 and our customer service team will assist you.
          </span>
          <div className='shade d-md-none' />
          <div className='menu-button d-md-none open' onClick={this.onOpenMenu}>
            <div className='lines' />
          </div>
          <nav>
            <div
              className='menu-button d-md-none close'
              onClick={this.onCloseMenu}
            >
              ✕
            </div>
            <ul className='links'>
              <li className='d-md-none'>
                <a href='/'>Home</a>
              </li>
              <li>
                <ControlLink
                  dataElementLocation={DataElementLocations.HEADER}
                  dataElementLabel={'Websites'}
                  dataElementId={'header-menu-item-wesbites'}
                >
                  <span
                    className='linkHead'
                    onClick={() => {
                      this.redirectWebdotcom('https://www.web.com/websites')
                      this.setState({
                        callToAction: true
                      })
                    }}
                  >Websites</span>
                </ControlLink>
              </li>
              <li>
                <ControlLink
                  dataElementLocation={DataElementLocations.HEADER}
                  dataElementLabel={'marketing'}
                  dataElementId={'header-menu-item-marketing'}
                >
                  <span
                    className='linkHead'
                    onClick={() => {
                      this.redirectWebdotcom('https://www.web.com/online-marketing')
                      this.setState({
                        callToAction: true
                      })
                    }}
                  >Marketing</span>
                </ControlLink>
              </li>
              <li>
                <ControlLink
                  dataElementLocation={DataElementLocations.HEADER}
                  dataElementLabel={'domains'}
                  dataElementId={'header-menu-item-domains'}
                >
                  <span
                    className='linkHead'
                    onClick={() => {
                      this.redirectWebdotcom('https://www.web.com/domains')
                      this.setState({
                        callToAction: true
                      })
                    }}
                  >Domains</span>
                </ControlLink>
              </li>
              <li>
                <ControlLink
                  dataElementLocation={DataElementLocations.HEADER}
                  dataElementLabel={'hosting'}
                  dataElementId={'header-menu-item-hosting'}
                >
                  <span
                    className='linkHead'
                    onClick={() => {
                      this.redirectWebdotcom('https://www.web.com/websites/hosting')
                      this.setState({
                        callToAction: true
                      })
                    }}
                  >Hosting</span>
                </ControlLink>
              </li>
              <li>
                <ControlLink
                  dataElementLocation={DataElementLocations.HEADER}
                  dataElementLabel={'security'}
                  dataElementId={'header-menu-item-security'}
                >
                  <span
                    className='linkHead'
                    onClick={() => {
                      this.redirectWebdotcom('https://www.web.com/website-security')
                      this.setState({
                        callToAction: true
                      })
                    }}
                  >Security</span>
                </ControlLink>
              </li>
              <li>
                <ControlLink
                  dataElementLocation={DataElementLocations.HEADER}
                  dataElementLabel={'email'}
                  dataElementId={'header-menu-item-email'}
                >
                  <span
                    className='linkHead'
                    onClick={() => {
                      this.redirectWebdotcom('https://www.web.com/email-service')
                      this.setState({
                        callToAction: true
                      })
                    }}
                  >Email</span>
                </ControlLink>
              </li>
              <li>
                <ControlLink
                  onClick={this.trackGaming}
                  dataElementLocation={DataElementLocations.HEADER}
                  dataElementLabel={'expert-services'}
                  dataElementId={'header-menu-item-expert-services'}
                >
                  <span
                    className='linkHead'
                    onClick={() => {
                      this.redirectWebdotcom('https://www.web.com/websites/pro-website-services')
                      this.setState({
                        callToAction: true
                      })
                    }}
                  >Expert Services</span>
                </ControlLink>
              </li>
              <li className='login'>
                <a
                  href={goToHref(process.env.GATSBY_LOGIN_URL)}
                  {...getDataProperty({
                    dataElementType: DataElementTypes.LINK,
                    dataElementLocation: DataElementLocations.HEADER,
                    dataElementLabel: 'log-in',
                    dataElementId: 'header-menu-item-log-in'
                  })}
                >
                  Log in
                </a>
              </li>
            </ul>
            <ul className='signup'>
              <li>
                <Button
                  dataElementLocation={DataElementLocations.HEADER}
                  dataElementLabel={'signup'}
                  dataElementId={'header-menu-item-signup'}
                  color='cta'
                  variant='contained'
                  className='signup-button'
                  onClick={() => {
                    this.redirectWebdotcom('https://www.web.com/websites/online-store-builder#packages')
                    this.setState({
                      callToAction: true
                    })
                  }}
                >
                  Sign up now
                </Button>
              </li>
            </ul>
            <ul className='d-md-none extra-links'>
              <li>
                <ControlLink
                  to='/terms'
                  dataElementLocation={DataElementLocations.HEADER}
                  dataElementLabel={'privacy-policy'}
                  dataElementId={'header-menu-item-privacy-policy'}
                >
                  Privacy policy
                </ControlLink>
              </li>
            </ul>
          </nav>
        </div>
        <div className={`containerCallToAction ${ this.state.callToAction ? 'visible' : 'novisible' }`}>
          <div className='callToActionBar' onClick={() => this.setState({ callToAction: false })}>
            <span>X</span>
          </div>
          <div className='callToActionContent'>
            <img className='callToActionImage' src={callToActionImage} alt='call to action' />
            <span className='callToActionDescription'>Our partner brand Web.com delivers everything you need to grow your business. Web.com One Stop. All Done.</span>
            <span className='callToActionRedirectText'>You will now be redirected to Web.com</span>
          </div>
        </div>
        <PopUpCallToAction callToAction={this.state.callToAction} setCallToAction={this} />
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  isWhite: PropTypes.bool,
  zindex: PropTypes.number,
}

Header.defaultProps = {
  siteTitle: ``,
  isWhite: false
}

export default Header
