/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import withLocalization from '@eig-builder/module-localization/withLocalization'
import { setBuilderBar } from '@eig-builder/core-utils/helpers/builder-bar-helper'

import Header from '../header'
import Footer from '../footer'
import './style/layout.scss'
import SocialProof from '../social-proof'

setBuilderBar(process.env.GATSBY_FOS_URL + '/')

const Layout = ({ children, isWhiteHeader, className, zindex }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header
          siteTitle={data.site.siteMetadata.title}
          isWhite={isWhiteHeader}
          zindex={zindex}
        />
        <main className={className}>{children}</main>
        <SocialProof />
        <Footer />
        <div id='teconsent-container' />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isWhiteHeader: PropTypes.bool,
  className: PropTypes.string,
  zindex: PropTypes.number,
}

export default withLocalization(Layout)
