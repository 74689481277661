import isEmpty from 'lodash/isEmpty'

export function addQueryParametersToUrl (url, queries, encodeQueryString) {
  let queryString = ''

  for (let query in queries) {
    if (encodeQueryString) {
      queryString += `&${ query }=${ encodeURIComponent(queries[query]) }`
    } else {
      queryString += `&${ query }=${ queries[query] }`
    }
  }
  if (url.indexOf('?') === -1 && !isEmpty(queries)) {
    queryString = queryString.replace(queryString.charAt(0), '?')
  }

  return url + queryString
}
