import React from 'react'

import useFetchAsJson from '@eig-builder/core-utils/hooks/useFetchAsJson'

import { SocialProofPopup } from '@eig-builder/module-social-proof-popup'

const SocialProof = () => {
  try {
    const url = `${ process.env.GATSBY_SOCIAL_PROOF_API_BASE_URL }/v1.0/customer_actions/websitebuilder`
    const fetchOptions = {
      url,
      useCache: false,
    }

    const [{ isLoading, data }] = useFetchAsJson(fetchOptions)

    // for now only customer_action_type 1 supported, add more descriptions here
    const getActionDescription = actionTypeCode => actionTypeCode === 1 ? 'published a website' : 'published a website'
    const getUtcDate = utcActionTime => new Date(Date.UTC(utcActionTime.getFullYear(), utcActionTime.getMonth(), utcActionTime.getDate(),
      utcActionTime.getHours(), utcActionTime.getMinutes(), utcActionTime.getSeconds(), utcActionTime.getMilliseconds()))
    const getText = customerAction => <React.Fragment><span style={{ fontWeight: 'bold' }}>{titleCase(customerAction.name)}</span> from {customerAction.country_code} {getActionDescription(customerAction.customer_action_type)}</React.Fragment>

    const titleCase = name => name.split(' ').map(w => w.length >= 1 ? w[0].toUpperCase() + w.substr(1).toLowerCase() : '').join(' ')

    const getData = isLoading ? [] : data.map(customerAction => {
      const utcActionTime = new Date(customerAction.action_time)
      return ({
        text: getText(customerAction),
        timestamp: getUtcDate(utcActionTime)
      })
    })

    return (!isLoading && <SocialProofPopup items={getData} delay={14000} autoHideDuration={10000} />)
  } catch {
    return ('')
  }
}

export default SocialProof
