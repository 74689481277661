import { addQueryParametersToUrl } from './querystring-helper'
import Cookies from 'universal-cookie'

export function goTo (url, target = '_self') {
  const cookies = new Cookies()

  const clickId = cookies.get('ir_cid')
  if (clickId) {
    url = addQueryParametersToUrl(url, {
      clickid: clickId
    })
  }

  url = addQueryParametersToUrl(url, {
    utm_source: 'websitebuilder',
    utm_medium: window.location.pathname
  })

  if (target === '_self') {
    window.location.href = url
  } else {
    window.open(url, target)
  }
}

export function goToHref (url) {
  const cookies = new Cookies()

  const clickId = cookies.get('ir_cid')
  if (clickId) {
    url = addQueryParametersToUrl(url, {
      clickid: clickId
    })
  }

  url = addQueryParametersToUrl(url, {
    utm_source: 'websitebuilder',
    utm_medium: typeof window !== `undefined` && window.location.pathname
  })

  return url
}
