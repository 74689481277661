import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { DataElementLocations, DataElementTypes, getDataProperty } from '@eig-builder/core-utils/helpers/tagging-helper'

const ControlLink = props => (<Link
  {...props}
  {...getDataProperty({
    dataElementType: DataElementTypes.LINK,
    dataElementLocation: DataElementLocations.HEADER,
    dataElementLabel: props.dataElementLabel,
    dataElementId: props.dataElementId
  })} />)
ControlLink.propTypes = {
  to: PropTypes.string,
  dataElementLabel: PropTypes.string,
  dataElementId: PropTypes.string
}

export default ControlLink
